<template>
  <div class="px-5" id="page-companies">
    <h5 class="mb-6 ml-2">SPAR Aktiverade föreningar</h5>

    <Confirm
      title="Bekräfta borttagning"
      message="Är du säker på att du vill ta bort föreningen?"
      ref="confirmModal"
      @cancel="cancelDelete"
      @confirm="confirmDelete"
    />

    
    <CompaniesTable
      :companies="companies"
      @createCompanyClicked="createCompanyClicked"
      @selectCompanyClicked="selectCompanyClicked"
      @deleteCompanyClicked="deleteCompanyClicked"
    />

    <b-card v-if="creating">
        <b-alert
          show
          variant="light"          
          >Genom att lägga till en förening i denna lista innebär det att den föreningen får tillgång till SPAR uppslag</b-alert>
        <b-form
          @submit.prevent="saveCompany"
        >
          <b-form-group id="company_id-group" label="FöreningsID" label-for="company_id">
            <b-form-input
              id="company_id"
              v-model="form.company_id"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="name-group" label="Namn på förening" label-for="name">
            <b-form-input
              id="namea"
              v-model="form.name"
            ></b-form-input>
          </b-form-group>
          
          <b-button type="submit" variant="primary" class="mt-2">Spara</b-button>
        </b-form>
    </b-card>
  
    <div class="scroll-to-container"></div>
  </div>
</template>

<style lang="scss" scoped>
.checkbox.checkbox-light-success > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import CompaniesTable from '@/view/pages/ml/companies/CompaniesTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import { DELETE_COMPANY } from '@/core/services/store/common.module';

export default {
  name: 'companies',
  components: {
    CompaniesTable,
    Confirm
  },
  mounted() {
    this.loadCompanies();
  },
  data() {
    return {
      form: {
        company_id:      '',
        name: 	        '',
      },
      creating: false,
      editing: false,
      
      companies: []
    };
  },
  watch: {
    
  },
  methods: {
    loadCompanies() {
      axios 
        .get(`/companies`)
        .then(res => {
          this.companies = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte hämta förening');
        });
    },
    createCompanyClicked() {
      this.creating = true;
    },
    saveCompany() {
      if (this.creating) {
        axios 
          .post('/company', this.form)
          .then(res => {
            if (res.status === 201) {
              this.companies.push(res.data);
              this.toastr('success', 'OK', 'Föreningen skapades');
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', 'Fel', 'Kunde inte skapa förening');
          });

      }
    },

    selectCompanyClicked(company_id) {
      this.company_id = company_id;
      
      axios 
        .get(`/company?company_id=${this.company_id}`)
        .then(res => {
          this.company = res.data.company;
          this.editing = true;

          this.scrollToEditCompany();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte hämta förening');
        });
    },
    deleteCompanyClicked(company_id) {
      axios 
        .delete(`/company?company_id=${company_id}`)
        .then(res => {
          this.companies = this.companies.filter(item => item.company_id !== company_id);
          
          this.toastr('success', 'OK', 'Föreningen togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte radera förening');
        });
    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    scrollToEditCompany() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    cancelDelete() {

    },
    confirmDelete() {

    },
    loadRegions() {
      axios
        .get('/location/regions')
        .then(res => {
          this.regions = res.data.regions;
          this.regionOptions = [];

          for (var k in this.regions) {
            this.regionOptions.push({ value: k, text: this.regions[k].region });
          }

          this.regionOptions.sort(function (a, b) {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte hämta regioner');
        });
    },
    toastr(type, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        appendToast: true,
        variant: type
      });
    },
  }
};
</script>
